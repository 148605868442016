import { VideoIdeasVideoIdeaDurationChoices } from "@/../__generated__/globalTypes";
import { i18nTranslate } from "@/plugins/i18n";
import { find } from "lodash";

export const videoLengthOptions = [
  {
    label: i18nTranslate("All"),
    value: null,
  },
  {
    label: i18nTranslate("5-8 seconds"),
    subLabel: i18nTranslate("Short Trends"),
    value: VideoIdeasVideoIdeaDurationChoices.LENGTH_5_TO_8_SECS,
  },
  {
    label: i18nTranslate("12-20 seconds"),
    subLabel: i18nTranslate("Tiktok Trends, Reels"),
    value: VideoIdeasVideoIdeaDurationChoices.LENGTH_12_TO_20_SECS,
  },
  {
    label: i18nTranslate("20-30 seconds"),
    subLabel: i18nTranslate("Explainers, Monologues, etc."),
    value: VideoIdeasVideoIdeaDurationChoices.LENGTH_20_TO_30_SECS,
  },
  {
    label: i18nTranslate("31-45 seconds"),
    subLabel: i18nTranslate("Ads"),
    value: VideoIdeasVideoIdeaDurationChoices.LENGTH_31_TO_45_SECS,
  },
];

export const videoLengthTextFormat = (
  videoIdeadDuration: VideoIdeasVideoIdeaDurationChoices
) => {
  return find(videoLengthOptions, (option) => {
    return option.value === videoIdeadDuration;
  })?.label;
};
